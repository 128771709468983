*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.align-items-center {
  -webkit-box-align: center !important;
  -webkit-align-items: center !important;
  -moz-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.container {
  width: 100% !important;
  max-width: 1275px !important;
  margin: 0 auto;
  padding: 0 15px;
}
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col {
  -webkit-flex-basis: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.col-auto {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}
.col-6 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
  -moz-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.MuiPaper-root {
  border: #eee 1px solid;
}
.MuiTable-root th {
  font-size: 15px;
  font-weight: bold;
  background: #f5f5f5;
}
.MuiTable-root th:nth-last-child(2) {
  width: 50px;
}
.MuiTable-root th:last-child {
  width: 115px;
}
.MuiTable-root td:last-child {
  padding-left: 0;
}
.citylist .MuiTable-root td:last-child {
  padding-left: 15px;
}

.react-confirm-alert-overlay {
  z-index: 9999 !important;
}
.react-confirm-alert-body {
  border: #929292 3px solid;
}
.filter-section {
  padding: 20px 20px 15px;
  border: #eee 1px solid;
  margin: 15px 0;
}
.filter-section .MuiFormControl-root {
  padding: 0 15px 15px 0;
  flex-grow: 1;
  max-width: 250px;
}
.search-field {
  display: flex;
}
.MuiTableCell-footer,
.MuiTypography-caption {
  font-size: 0.875rem !important;
  color: #000 !important;
}
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: rgba(0, 131, 68, 0.1) !important;
  color: #008344;
}
.main-navigation .MuiListItem-root.Mui-selected,
.main-navigation .MuiListItem-root.Mui-selected:hover {
  border-right: #008344 3px solid;
}
.MuiListItem-root button {
  color: #afadad;
}
.MuiListItem-root.Mui-selected button,
.MuiListItem-root.Mui-selected:hover button {
  color: #008344;
}
.MuiMenuItem-root {
  padding: 4px 15px !important;
  min-height: initial !important;
}
.languagebox .MuiSelect-select:focus {
  background: transparent;
}
.languagebox.MuiInput-underline:after,
.languagebox.MuiInput-underline:hover:not(.Mui-disabled):before,
.languagebox.MuiInput-underline:before,
.languagebox.MuiInput-underline:after {
  display: none;
}
.MuiListItemIcon-root {
  min-width: 32px !important;
}
.MuiCollapse-wrapperInner {
  padding-bottom: 20px;
}
.MuiCollapse-wrapperInner .MuiButtonBase-root {
  padding-left: 45px;
}
.usercat-table .MuiTable-root th:nth-last-child(2) {
  width: 150px;
}
.country-list .MuiTable-root th:last-child {
  width: 175px;
}
.country-list .MuiTable-root td:last-child {
  padding-left: 15px;
}
.datepicker .MuiIconButton-root {
  position: absolute;
  left: 0;
  width: 100%;
  border-radius: 0;
  top: 0;
  height: 100%;
  text-align: right;
}
.datepicker .MuiIconButton-label {
  width: auto;
  position: absolute;
  right: 0;
}
.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.8) !important;
}
.react-confirm-alert-body {
  border-radius: 0 !important;
  border: 0 !important;
}
.react-confirm-alert-body > h1 {
  font-size: 25px;
}
.react-confirm-alert-button-group button:first-child {
  background: #008344;
}
.react-confirm-alert-button-group button:last-child {
  background: #929292;
}

/* updates */
.MuiOutlinedInput-notchedOutline {
  border-color: #b3d0bd !important;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #ff978f !important;
}

.MuiOutlinedInput-input {
  padding: 15px 20px !important;
}

.MuiButton-root {
  padding: 12px 35px !important;
  border-radius: 50rem !important;
}

.card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 25px;
  height: 100%;
}

.MuiButton-outlinedPrimary {
  border: 2px solid #34cc67 !important;
}
.MuiButton-outlinedPrimary:hover {
  background-color: #34cc67 !important;
  color: #ffffff;
}

.MuiButton-containedPrimary {
  border: 2px solid #34cc67 !important;
}
.MuiButton-containedPrimary:hover {
  border: 2px solid #2cae57 !important;
}
