.HeaderNavigation {
  margin-bottom: 45px;
  @media (max-width: 1274px) {
    margin-bottom: 30px;
  }
  @media (max-width: 991px) {
    margin-bottom: 20px;
  }
}
.navigation {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  font-size: 13px;
  display: flex;
  font-weight: 600;
  width: 100%;
  flex-wrap: wrap;
  @media (max-width: 1274px) {
    font-size: 12px;
    &.inactive {
      display: none;
    }
  }
  @media (max-width: 991px) {
    flex-direction: column;
    font-size: 13px;
  }
  li {
    padding: 8px 14px;
    display: inline-flex;
    align-items: center;
    @media (max-width: 1274px) {
      padding: 8px 6px;
    }
    @media (max-width: 991px) {
      padding: 10px 25px;
    }
    a {
      text-decoration: none;
      color: #6e6e6e;
      display: inline-flex;
      align-items: center;
      width: 100%;
      &.active {
        color: #5a76d0;
      }
    }
  }
  &__icon {
    font-size: 24px !important;
    margin-right: 8px;
    @media (max-width: 1274px) {
      font-size: 14px !important;
      margin-right: 4px;
    }
    @media (max-width: 991px) {
      font-size: 18px !important;
      margin-right: 8px;
    }
  }
  &__button {
    padding: 6px 20px !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    color: #ffffff !important;
  }
  &__last-item {
    margin-left: auto;
  }
}

.navigation-dropdown {
  font-size: 13px !important;
  font-weight: 700 !important;
  padding-top: 5px;
  width: 110px;
  &:before,
  &:after {
    display: none;
  }
}
