.loggedUser {
  position: relative;
  display: inline-block;
  padding: 5px 0;
  &:hover {
    .loggedUser__dropdown {
      opacity: 1;
      visibility: visible;
      z-index: 999;
    }
  }
  &__name {
    color: #525451;
    font-size: 16px;
    @media (max-width: 575px) {
      display: none;
    }
  }
  &__dp {
    display: inline-block;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    text-align: center;
    line-height: 38px;
    background: #34cc67;
    margin-left: 10px;
    font-weight: 700;
    color: #ffffff;
    font-size: 20px;
  }
  &__name-dp {
    cursor: pointer;
  }
  &__dropdown {
    position: absolute;
    right: 0;
    top: 100%;
    background: #ffffff;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.18);
    padding: 10px 0;
    border-radius: 4px;
    min-width: 180px;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }
  &__dropdown-item {
    display: flex;
    align-items: center;
    color: #848484;
    text-decoration: none;
    padding: 10px 15px;
    font-size: 14px;
    &:hover {
      background-color: #ececec;
    }
  }
  &__icon {
    margin-right: 10px;
    font-size: 20px !important;
  }
}

body {
  background-color: #f4f7f9;
}

.setting__link {
  color: #525451;
  text-decoration: none;
  font-size: 20px;
}

header {
  background: #ffffff;
  padding: 12px;
  border: 1px solid #ececec;
  @media (max-width: 991px) {
    padding: 12px 0;
  }
}
