@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?vx4iui');
  src: url('../fonts/icomoon.eot?vx4iui#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?vx4iui') format('truetype'),
    url('../fonts/icomoon.woff?vx4iui') format('woff'),
    url('../fonts/icomoon.svg?vx4iui#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-logout:before {
  content: '\e911';
}
.icon-user:before {
  content: '\e912';
}
.icon-envelope:before {
  content: '\e910';
}
.icon-manage-account:before {
  content: '\e90f';
}
.icon-click:before {
  content: '\e900';
}
.icon-conversion-funnel:before {
  content: '\e901';
}
.icon-conversion-report:before {
  content: '\e902';
}
.icon-dashboard:before {
  content: '\e903';
}
.icon-delete:before {
  content: '\e904';
}
.icon-edit:before {
  content: '\e905';
}
.icon-product-report:before {
  content: '\e906';
}
.icon-products:before {
  content: '\e907';
}
.icon-recommendation:before {
  content: '\e908';
}
.icon-revenue:before {
  content: '\e909';
}
.icon-settings:before {
  content: '\e90a';
}
.icon-tick:before {
  content: '\e90b';
}
.icon-training-report:before {
  content: '\e90c';
}
.icon-users:before {
  content: '\e90d';
}
.icon-view:before {
  content: '\e90e';
}
